import type { Component } from 'solid-js';
import { createSignal } from 'solid-js';
import styles from './Layout.module.scss';
import { Outlet, useNavigate } from '@solidjs/router';
import localStorageService from '../../services/LocalStorageService';

const Layout: Component = () => {
  const navigate = useNavigate();
  const [allowed, setAllowed] = createSignal(false);

  const checkPermission = () => {
    const token = localStorageService.getToken();
    if (token) {
      setAllowed(true);
    }
  };

  checkPermission();

  if (allowed()) {
    navigate('/home');
  }

  return allowed() ? null : (
    <>
      <div id="modal" class={`${styles.modal} max-w-7xl`}></div>
      <Outlet />
    </>
  );
};

export default Layout;
