const setUserProfile = (profile: any) => {
  localStorage.setItem('userProfile', JSON.stringify(profile));
};

const getUserProfile = () => {
  const profile = localStorage.getItem('userProfile');
  return profile ? JSON.parse(profile) : null;
};

const removeUserProfile = () => {
  localStorage.removeItem('userProfile');
};

const setToken = (token: string, expiration: number) => {
  localStorage.setItem('token', JSON.stringify({ value: token, expiration }));
};

const getToken = () => {
  const tokenItem = localStorage.getItem('token');
  if (!tokenItem) return null;

  const token = JSON.parse(tokenItem);
  if (token.expiration < Date.now() / 1000) {
    removeToken();
    return null;
  }

  return token.value;
};

const removeToken = () => {
  localStorage.removeItem('token');
};

export default {
  setUserProfile,
  getUserProfile,
  removeUserProfile,
  setToken,
  getToken,
  removeToken,
};
