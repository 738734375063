import { createSignal } from 'solid-js';
import type {
  NostrEvent,
  NostrEOSE,
  NostrEventType,
  NostrEventContent,
  PrimalWindow,
} from './types/primal';

export const [socket, setSocket] = createSignal<WebSocket>();

export const [isConnected, setConnected] = createSignal<boolean>(false);

export const isNotConnected = () => !isConnected();

const onOpen = () => {
  setConnected(true);
  if (localStorage.getItem('devMode') === 'true') {
    const hook = (window as PrimalWindow).onPrimalUploadServerConnected;

    hook && hook(uploadServer, socket());
  }
};

const onClose = () => {
  setConnected(false);

  socket()?.removeEventListener('open', onOpen);
  socket()?.removeEventListener('close', onClose);
  socket()?.removeEventListener('error', onError);

  setTimeout(() => {
    connect();
  }, 200);
};

const onError = (error: Event) => {
  // eslint-disable-next-line no-console
  console.log('ws error: ', error);
};

export let uploadServer = '';

export const connect = () => {
  if (isNotConnected()) {
    uploadServer = localStorage.getItem('uploadServer') || import.meta.env.PRIMAL_UPLOAD_URL;

    setSocket(new WebSocket(uploadServer));
    // eslint-disable-next-line no-console
    console.log('UPLOAD SOCKET: ', socket());

    socket()?.addEventListener('open', onOpen);
    socket()?.addEventListener('close', onClose);
    socket()?.addEventListener('error', onError);
  }
};

export const disconnect = () => {
  socket()?.close();
};

export const reset = () => {
  disconnect();
  setTimeout(connect, 1000);
};

export const sendMessage = (message: string) => {
  if (isConnected()) {
    const e = new CustomEvent('send', { detail: { message, ws: socket() } });
    socket()?.send(message);
    socket()?.dispatchEvent(e);
  }
};

export const refreshSocketListeners = (
  ws: WebSocket | undefined,
  listeners: Record<string, (event: any) => any>,
) => {
  if (!ws) {
    return;
  }

  Object.keys(listeners).forEach((event: string) => {
    ws.removeEventListener(event, listeners[event]);
    ws.addEventListener(event, listeners[event]);
  });
};

export const removeSocketListeners = (
  ws: WebSocket | undefined,
  listeners: Record<string, (event: any) => any>,
) => {
  if (!ws) {
    return;
  }

  Object.keys(listeners).forEach((event: string) => {
    ws.removeEventListener(event, listeners[event]);
  });
};

export const subscribeTo = (
  subId: string,
  cb: (type: NostrEventType, subId: string, content?: NostrEventContent) => void,
) => {
  const listener = (event: MessageEvent) => {
    const message: NostrEvent | NostrEOSE = JSON.parse(event.data);
    const [type, subscriptionId, content] = message;

    if (subId === subscriptionId) {
      cb(type, subscriptionId, content);
    }
  };

  socket()?.addEventListener('message', listener);

  return () => {
    socket()?.removeEventListener('message', listener);
  };
};
