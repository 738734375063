import type { Component } from 'solid-js';
import { createResource, lazy } from 'solid-js';
import type { RouteDataFuncArgs } from '@solidjs/router';
import { Routes, Route, Navigate } from '@solidjs/router';

import type { PrimalWindow } from './types/primal';
import { fetchKnownProfiles } from './lib/profile';

import { useHomeContext } from './contexts/HomeContext';
import { useExploreContext } from './contexts/ExploreContext';
import { useThreadContext } from './contexts/ThreadContext';
import { useAccountContext } from './contexts/AccountContext';
import { useProfileContext } from './contexts/ProfileContext';
import { useSettingsContext } from './contexts/SettingsContext';
import { useMessagesContext } from './contexts/MessagesContext';
import { useMediaContext } from './contexts/MediaContext';
import { useNotificationsContext } from './contexts/NotificationsContext';
import { useSearchContext } from './contexts/SearchContext';
import PublicLayout from './components/Layout/PublicLayout';

const Login = lazy(() => import('./pages/Login'));
const Register = lazy(() => import('./pages/Register'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const HowToGetNos2x = lazy(() => import('./pages/HowToGetNos2x'));
const TermsOfService = lazy(() => import('./pages/TermsOfService'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const Home = lazy(() => import('./pages/Home'));
const ProtectedLayout = lazy(() => import('./components/Layout/ProtectedLayout'));
const Explore = lazy(() => import('./pages/Explore'));
const Thread = lazy(() => import('./pages/Thread'));
const Messages = lazy(() => import('./pages/Messages'));
const Airdrop = lazy(() => import('./pages/Airdrop'));
const Referral = lazy(() => import('./pages/Referral'));
const Support = lazy(() => import('./pages/Support'));
const Leaderboard = lazy(() => import('./pages/Leaderboard'));
const Notifications = lazy(() => import('./pages/Notifications'));
const Downloads = lazy(() => import('./pages/Downloads'));
const Help = lazy(() => import('./pages/Help'));
const Search = lazy(() => import('./pages/Search'));
const NotFound = lazy(() => import('./pages/NotFound'));
const EditProfile = lazy(() => import('./pages/EditProfile'));
const Profile = lazy(() => import('./pages/Profile'));
const Mutelist = lazy(() => import('./pages/Mutelist'));
const Wallet = lazy(() => import('./pages/Wallet'));
const Socials = lazy(() => import('./pages/Socials'));

const GemsSettings = lazy(() => import('./pages/GemsSettings'));
const NostrSettings = lazy(() => import('./pages/Settings/Settings'));
const NotifSettings = lazy(() => import('./pages/Settings/Notifications'));
const Appearance = lazy(() => import('./pages/Settings/Appearance'));
const HomeFeeds = lazy(() => import('./pages/Settings/HomeFeeds'));
const ZapSettings = lazy(() => import('./pages/Settings/Zaps'));
const Muted = lazy(() => import('./pages/Settings/Muted'));
const Network = lazy(() => import('./pages/Settings/Network'));
const Moderation = lazy(() => import('./pages/Settings/Moderation'));
const Menu = lazy(() => import('./pages/Settings/Menu'));

const primalWindow = window as PrimalWindow;

const isDev = localStorage.getItem('devMode') === 'true';

const Router: Component = () => {
  const account = useAccountContext();
  const profile = useProfileContext();
  const settings = useSettingsContext();
  const home = useHomeContext();
  const explore = useExploreContext();
  const thread = useThreadContext();
  const messages = useMessagesContext();
  const media = useMediaContext();
  const notifications = useNotificationsContext();
  const search = useSearchContext();

  if (isDev) {
    primalWindow.primal = {
      account,
      explore,
      home,
      media,
      messages,
      notifications,
      profile,
      search,
      settings,
      thread,
    };

    primalWindow.onPrimalComponentMount = () => {};
    primalWindow.onPrimalComponentCleanup = () => {};
    primalWindow.onPrimalCacheServerConnected = () => {};
    primalWindow.onPrimalUploadServerConnected = () => {};
  }

  const getKnownProfiles = ({ params }: RouteDataFuncArgs) => {
    const [profiles] = createResource(params.vanityName, fetchKnownProfiles);
    return profiles;
  };

  return (
    <>
      <Routes>
        <Route path="/">
          <Route path="/" component={PublicLayout}>
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/get-nostr-key" component={HowToGetNos2x} />
            <Route path="/terms-of-service" component={TermsOfService} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
          </Route>
          <Route path="/" element={<Navigate href="/login" />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/" component={ProtectedLayout}>
            <Route path="/home" component={Home} />
            <Route path="/thread/:postId" component={Thread} />
            <Route path="/e/:postId" component={Thread} />
            <Route path="/explore/:scope?/:timeframe?" component={Explore} />
            <Route path="/messages/:sender?" component={Messages} />
            <Route path="/airdrop" component={Airdrop} />
            <Route path="/referral" component={Referral} />
            <Route path="/support" component={Support} />
            <Route path="/socials" component={Socials} />
            <Route path="/leaderboard" component={Leaderboard} />
            <Route path="/notifications" component={Notifications} />
            <Route path="/downloads" component={Downloads} />
            <Route path="/download" element={<Navigate href="/downloads" />} />;
            <Route path="/settings/nostr" component={NostrSettings}>
              <Route path="/" component={Menu} />
              <Route path="/appearance" component={Appearance} />
              <Route path="/feeds" component={HomeFeeds} />
              <Route path="/notifications" component={NotifSettings} />
              <Route path="/zaps" component={ZapSettings} />
              <Route path="/muted" component={Muted} />
              <Route path="/network" component={Network} />
              <Route path="/filters" component={Moderation} />
            </Route>
            <Route path="/settings/gems" component={GemsSettings} />
            <Route path="/settings/profile" component={EditProfile} />
            <Route path="/wallet" component={Wallet} />
            <Route path="/profile/:npub?" component={Profile} />
            <Route path="/p/:npub?" component={Profile} />
            <Route path="/help" component={Help} />
            <Route path="/search/:query" component={Search} />
            <Route path="/rest" component={Explore} />
            <Route path="/mutelist/:npub" component={Mutelist} />
            <Route path="/404" component={NotFound} />
            <Route path="/:vanityName" component={Profile} data={getKnownProfiles} />
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default Router;
