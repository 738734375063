import type { Component } from 'solid-js';
import { onCleanup, onMount } from 'solid-js';
import { AccountProvider } from './contexts/AccountContext';
import { connect, disconnect } from './sockets';
import { connect as uploadConnect, disconnect as uploadDisconnet } from './uploadSocket';
import styles from './App.module.scss';
import Toaster from './components/Toaster/Toaster';
import { HomeProvider } from './contexts/HomeContext';
import { ExploreProvider } from './contexts/ExploreContext';
import { ThreadProvider } from './contexts/ThreadContext';
import Router from './Router';
import { ProfileProvider } from './contexts/ProfileContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { TranslatorProvider } from './contexts/TranslatorContext';
import { NotificationsProvider } from './contexts/NotificationsContext';
import { SearchProvider } from './contexts/SearchContext';
import { MessagesProvider } from './contexts/MessagesContext';
import { MediaProvider } from './contexts/MediaContext';

export const APP_ID = `${Math.floor(Math.random() * 10000000000)}`;

const getPlausibleDomain = () => {
  return import.meta.env.PRIMAL_PLAUSIBLE_DOMAIN;
};

const App: Component = () => {
  onMount(() => {
    connect();
    uploadConnect();

    const script = document.createElement('script');

    script.setAttribute('defer', '');
    script.setAttribute('src', 'https://plausible.io/js/script.js');
    script.setAttribute('data-domain', getPlausibleDomain());

    document.head.appendChild(script);
  });

  onCleanup(() => {
    disconnect();
    uploadDisconnet();
  });

  return (
    <TranslatorProvider>
      <Toaster>
        <MediaProvider>
          <input id="defocus" class={styles.invisible} />
          <Router />
        </MediaProvider>
      </Toaster>
    </TranslatorProvider>
  );
};

export default App;
